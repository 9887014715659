module Main exposing (main)

import Browser
import Data.ApartmentId as ApartmentId
import Data.AudioUrl as AudioUrl exposing (AudioUrl)
import Data.CallTimeOut as CallTimeOut
import Data.Id as Id
import Data.Ivr as Ivr
import Data.Ivr.ConferenceCall as ConferenceCall
import Data.Ivr.Gather as Gather
import Data.Ivr.MultiDigitGather as MultiDigitGather
import Data.Ivr.PlayAudio as PlayAudio
import Data.Ivr.Recording as Recording
import Data.Ivr.SendSmsMessage as SendSmsMessage
import Data.Ivr.TimeOfDay as TimeOfDay
import Data.Ivr.Transfer as Transfer
import Data.PMCId as PMCId
import Data.PassCallerId as PassCallerId
import Data.PhoneNumber as PhoneNumber
import Data.RecordAudio as RecordAudio
import Data.TransferPathway as TransferPathway
import Html exposing (..)
import Html.Attributes exposing (checked, class, controls, src, type_, value)
import Html.Events exposing (onInput)
import Json.Decode as Decode


main : Program () Model Msg
main =
    Browser.sandbox { init = init, update = update, view = view }


type ParseResult
    = SingleIvr (Result Decode.Error Ivr.Ivr)
    | MultiIvr (List (Result Decode.Error Ivr.Ivr))


type alias Model =
    { jsonData : Maybe String
    , decodedIvr : Maybe ParseResult
    }


init : Model
init =
    { jsonData = Nothing
    , decodedIvr = Nothing
    }


type Msg
    = SetJsonData String


update : Msg -> Model -> Model
update msg model =
    case msg of
        SetJsonData value ->
            if String.trim value == "" then
                { model | jsonData = Nothing, decodedIvr = Nothing }

            else
                { model
                    | jsonData = Just value
                    , decodedIvr = Just <| parseInput value
                }


parseInput : String -> ParseResult
parseInput value =
    let
        multiIvrDecode =
            Decode.list Decode.value
                |> Decode.andThen
                    (\listOfIvrs ->
                        Decode.succeed (List.map (Decode.decodeValue Ivr.decoder) listOfIvrs)
                    )
    in
    case Decode.decodeString multiIvrDecode value of
        Ok listOfIvrs ->
            MultiIvr listOfIvrs

        Err _ ->
            SingleIvr (Decode.decodeString Ivr.decoder value)


view : Model -> Html Msg
view model =
    div [ class "mx-auto container" ]
        [ ivrHeader
        , textarea [ class "border border-gray-400 p-4 w-full h-24", onInput SetJsonData ] [ text <| Maybe.withDefault "" model.jsonData ]
        , Maybe.withDefault (text "") <| Maybe.map displayParsedItems model.decodedIvr
        ]


displayParsedItems : ParseResult -> Html a
displayParsedItems parseResult =
    case parseResult of
        SingleIvr (Ok ivr) ->
            displayFinalIvr ivr

        SingleIvr (Err error) ->
            div [] [ text <| Decode.errorToString error ]

        MultiIvr list ->
            let
                parsedResultClass result =
                    case result of
                        Ok _ ->
                            "bg-green-100"

                        Err _ ->
                            "bg-red-100"
            in
            ul [] (List.map (\result -> li [ class <| parsedResultClass result ] [ details [] [ displayParsedItems <| SingleIvr result ] ]) list)


displayFinalIvr : Ivr.Ivr -> Html a
displayFinalIvr ivr =
    div []
        [ h2 [ class "text-2xl font-semibold" ] [ text <| "Apartment " ++ ApartmentId.toString (Ivr.apartmentId ivr) ]
        , h2 [ class "text-2xl font-semibold" ] [ text <| "PMC Id " ++ PMCId.toString (Ivr.pmcId ivr) ]
        , h2 [ class "text-2xl font-semibold" ] [ text <| "Phone Number " ++ PhoneNumber.toString (Ivr.phoneNumber ivr) ]
        , ul [ class "flex flex-col" ] (List.map (\action -> li [] [ displayIvrAction action ]) (Ivr.profileItems ivr))
        ]


displayGatherChild : Gather.GatherChildren -> Html a
displayGatherChild gatherChild =
    div [ class "flex" ]
        [ text <| Gather.gatherChildrenGather gatherChild
        , displayIvrAction <| Gather.gatherChildrenToIvr gatherChild
        ]


displayIvrAction : Gather.IvrAction -> Html a
displayIvrAction ivrAction =
    let
        container cxs heading rest =
            div [ class <| "w-full p-4 border " ++ cxs ]
                (h3 [ class "text-xl font-semibold" ] [ text heading ] :: rest)

        inputWrapper =
            div [ class "flex flex-col" ]
    in
    case ivrAction of
        Gather.IvrPlayAudio playAudio ->
            container "bg-red-100 border-red-300"
                "Play Audio"
                [ displayId <| PlayAudio.id playAudio
                , displayAudio <| PlayAudio.audioUrl playAudio
                ]

        Gather.IvrGather gather ->
            container "bg-green-100 border-green-300"
                "Gather"
                [ div []
                    [ div [ class "flex" ]
                        [ displayId <| Gather.id gather
                        , displayAudio <| Gather.audioUrl gather
                        ]
                    , ul [ class "ml-8" ] (List.map (\child -> li [ class "w-full" ] [ displayGatherChild child ]) (Gather.children gather))
                    ]
                ]

        Gather.IvrRecording recording ->
            container "bg-orange-100 border-orange-300"
                "Recording"
                [ displayId <| Recording.id recording
                , displayAudio <| Recording.audioUrl recording
                ]

        Gather.IvrTransfer transfer ->
            container "bg-yellow-100 border-yellow-300"
                "Transfer"
                [ displayId <| Transfer.id transfer
                , displayTransferPathway <| Transfer.transferPathway transfer
                , inputWrapper
                    [ label [] [ text <| "Call Time Out: " ]
                    , input
                        [ type_ "number"
                        , value <| Maybe.withDefault "" <| Maybe.map String.fromInt <| CallTimeOut.toInt (Transfer.callTimeOut transfer)
                        ]
                        []
                    ]
                , inputWrapper
                    [ label [] [ text <| "Pass Caller Id? " ]
                    , input [ type_ "checkbox", checked <| PassCallerId.toBool <| Transfer.passCallerId transfer ] []
                    ]
                , inputWrapper
                    [ label [] [ text <| "Record Audio?" ]
                    , input [ type_ "checkbox", checked <| RecordAudio.toBool <| Transfer.recordAudio transfer ] []
                    ]
                ]

        Gather.IvrConferenceCall record ->
            container "bg-blue-100 border-blue-300"
                "Conference Call"
                [ displayId <| ConferenceCall.id record
                , displayIvrTimeOfDay <| ConferenceCall.timeOfDay record
                , inputWrapper
                    [ label [] [ text <| "Bridge Phone Number: " ]
                    , input
                        [ type_ "number"
                        , value <| PhoneNumber.bridgeNumberToString <| ConferenceCall.bridgePhoneNumber record
                        ]
                        []
                    ]
                , inputWrapper
                    [ label [] [ text <| "Pass Caller Id? " ]
                    , input [ type_ "checkbox", checked <| PassCallerId.toBool <| ConferenceCall.passCallerId record ] []
                    ]
                , inputWrapper
                    [ label [] [ text <| "Record Audio?" ]
                    , input [ type_ "checkbox", checked <| RecordAudio.toBool <| ConferenceCall.recordAudio record ] []
                    ]
                ]

        Gather.IvrMultiDigitGather multiDigitGather ->
            container "bg-purple-100 border-purple-300"
                "Multi-Digit Gather"
                [ displayId <| MultiDigitGather.id multiDigitGather
                , displayAudio <| MultiDigitGather.audioUrl multiDigitGather
                ]

        Gather.IvrSendSmsMessage sendSmsMessage ->
            container "bg-blue-100 border-blue-300"
                "Send SMS Message"
                [ displayId <| SendSmsMessage.id sendSmsMessage
                , displayAudio <| SendSmsMessage.audioUrl sendSmsMessage
                , inputWrapper
                    [ label [] [ text <| "SMS Message" ]
                    , input [ type_ "text", value <| SendSmsMessage.message sendSmsMessage ] []
                    ]
                ]


displayId : Id.Id -> Html a
displayId id =
    span [] [ text <| Id.toString id ]


displayAudio : AudioUrl -> Html a
displayAudio url =
    audio
        [ src <| AudioUrl.toString url
        , controls True
        , class "w-32 h-8"
        ]
        []


displayIvrTimeOfDay : TimeOfDay.TimeOfDay -> Html a
displayIvrTimeOfDay ivrTimeOfDay =
    case ivrTimeOfDay of
        TimeOfDay.OffHour ->
            p [] [ text "Off-hours" ]

        TimeOfDay.OfficeHour ->
            p [] [ text "Business hours" ]

        TimeOfDay.AllHours ->
            span [] []


displayTransferPathway : TransferPathway.TransferPathway -> Html a
displayTransferPathway transferPathway =
    case transferPathway of
        TransferPathway.PlayAudio audioUrl ->
            displayAudio audioUrl

        TransferPathway.Forward phoneNumber ->
            div [ class "flex flex-col" ]
                [ label [] [ text "Forward Phone Number" ]
                , input [ type_ "text", value <| PhoneNumber.forwardNumberToString phoneNumber ] []
                ]


ivrHeader : Html a
ivrHeader =
    h1 [ class "text-3xl font-semibold" ] [ text "IVR Generator" ]
